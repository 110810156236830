import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { redeemModalToggle } from '@/actions/payment';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import RedeemPointsConfirmationModal from './RedeemPointsConfirmationModal';
import useReedemConfirmation from './RedeemPointsConfirmationModal/hooks/useRedeemConfirmation';
import { RedeemContext } from './RedeemHandlerContext';

/**
 * @description Redeem handler
 */
const RedeemHandler = () => {
  const { OPT_CONTACT_METHOD_AT_REDEEM_POINTS: optContactMethod } = useWhitelabelFeatures();
  const dispatch = useDispatch();
  const $redeemModalToggle = useCallback(() => dispatch(redeemModalToggle(false)), [dispatch]);
  const [selectedMethod, setSelectedMethod] = useState(
    optContactMethod === 'both' ? 'email' : optContactMethod,
  );

  const { isCreating, isConfirming, codeSent, error, createNITCode, confirmNITCode } =
    useReedemConfirmation();

  const handleOnClose = useCallback(() => {
    $redeemModalToggle();
  }, [$redeemModalToggle]);

  const handleOnSuccess = useCallback(() => {
    $redeemModalToggle();
  }, [$redeemModalToggle]);

  const handleOnSendConfirmation = useCallback(() => {
    if (codeSent) return;
    createNITCode(selectedMethod);
  }, [createNITCode, codeSent, selectedMethod]);

  const handleOnConfirm = useCallback(
    async (code) => {
      const response = await confirmNITCode(code);

      if (response) {
        handleOnSuccess();
      }
    },
    [confirmNITCode, handleOnSuccess],
  );

  const handleOnResendCode = useCallback(() => {
    createNITCode(selectedMethod);
  }, [createNITCode, selectedMethod]);

  const contextValue = useMemo(
    () => ({
      optContactMethod,
      isOpen: true,
      isCreating,
      isConfirming,
      codeSent,
      error,
      selectedMethod,
      onClose: handleOnClose,
      onSendConfirmation: handleOnSendConfirmation,
      onConfirm: handleOnConfirm,
      onResendCode: handleOnResendCode,
      onSelectMethod: setSelectedMethod,
    }),
    [
      optContactMethod,
      handleOnClose,
      handleOnSendConfirmation,
      handleOnConfirm,
      handleOnResendCode,
      isCreating,
      isConfirming,
      codeSent,
      error,
      selectedMethod,
    ],
  );

  return (
    <RedeemContext.Provider value={contextValue}>
      <RedeemPointsConfirmationModal />
    </RedeemContext.Provider>
  );
};

RedeemHandler.propTypes = {};

export default RedeemHandler;
