import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { trackEvent } from 'user-analytics';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '@/actions';
import { setFrequentPassengers as setFrequentPassengersAction } from '@/actions/userPreferences';
import useUserPreferences from 'hooks/useUserPreferences';
import { adaptPassengersData } from 'utils/userPreferences';
import userPreferencesService from 'services/userPreferences';
import { change } from 'redux-form';
import FrequentPassengersContext from './FrequentPassengersContext';
/**
 * FrequentPassengersProvider component.
 * Manages state and operations for frequent passengers functionality.
 * @param {object} props - children.
 * @param {React.ReactNode} props.children - Child components to be wrapped.
 */
const FrequentPassengersProvider = ({ children }) => {
  const { passengers } = useUserPreferences();
  const formPassengersState = useSelector((state) => state.form.passengers.values.passengers);
  const dispatch = useDispatch();
  const adaptedPassengers = adaptPassengersData(passengers).map((passenger) => {
    const initialPassengerMatch = formPassengersState
      .filter((passenger) => passenger.firstName?.trim() !== '')
      .find(
        (initialPassenger) =>
          initialPassenger.firstName?.trim().toLowerCase() ===
            passenger.firstName?.trim().toLowerCase() &&
          initialPassenger.lastName?.trim().toLowerCase() ===
            passenger.lastName?.trim().toLowerCase(),
      );
    const initialPassengerIndexMatch = formPassengersState
      .filter((passenger) => passenger.firstName?.trim() !== '')
      .findIndex(
        (initialPassenger) =>
          initialPassenger.firstName?.trim().toLowerCase() ===
            passenger.firstName?.trim().toLowerCase() &&
          initialPassenger.lastName?.trim().toLowerCase() ===
            passenger.lastName?.trim().toLowerCase(),
      );
    return {
      ...passenger,
      selectedForPassenger: initialPassengerMatch ? initialPassengerIndexMatch + 1 : null,
      isSelected: Boolean(initialPassengerMatch),
    };
  });
  const [frequentPassengers, setFrequentPassengers] = useState(adaptedPassengers);
  const frequentPassengersRef = useRef();
  frequentPassengersRef.current = frequentPassengers;
  const { t } = useTranslation();
  /*
   * The array looks like this:
   * [
   *  {
   *    passenger: {} // frequent passenger object
   *    timeoutId,
   *  },
   * ]
   */
  const pendingPassengerDeletions = useRef([]);

  const trackFrequentPassengerSelected = (passenger, from) => {
    trackEvent('Frequent Passenger Selected', {
      Passenger: {
        'First Name': passenger.firstName,
        'Last Name': passenger.lastName,
        'Email': passenger.email || 'N/A',
        'Passenger Number': passenger.selectedForPassenger,
      },
      From: from,
    });
  };

  useEffect(() => {
    const currentRoute = window.location.pathname;
    const isPassengersRoute = currentRoute.includes('/passengers');
    if (!isPassengersRoute) {
      return;
    }
    if (frequentPassengers.length) {
      trackEvent('Frequent Passengers Showed', {
        'Passengers Count': frequentPassengers.length,
        'Passengers': frequentPassengers.map((passenger) => ({
          'First Name': passenger.firstName,
          'Last Name': passenger.lastName,
          'Email': passenger.email || 'N/A',
          'Passenger Number': passenger.selectedForPassenger,
          'Selected': passenger.isSelected,
        })),
      });
    }
    dispatch(setFrequentPassengersAction(adaptedPassengers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePendingPassengerDeletion = (id) => {
    pendingPassengerDeletions.current = pendingPassengerDeletions.current.filter(
      (deletion) => deletion.passenger.id !== id,
    );
  };

  const undoDeleteFrequentPassenger = (id) => {
    const deletion = pendingPassengerDeletions.current.find(
      (deletion) => deletion.passenger.id === id,
    );
    clearTimeout(deletion.timeoutId);
    /**
     * Since this method is called from a timeout, we need to use the ref to have access to the latest state.
     * Otherwise, we would be using the stale state from when the timeout was set.
     */
    setFrequentPassengers([...frequentPassengersRef.current, deletion.passenger]);
    trackEvent('Frequent Passenger Delete Undone', {
      Passenger: {
        'First Name': deletion.passenger.firstName,
        'Last Name': deletion.passenger.lastName,
        'Email': deletion.passenger.email || 'N/A',
      },
    });
    removePendingPassengerDeletion(id);
  };

  /**
   * Removes a frequent passenger from the list.
   * @param {string} id - ID of the passenger to remove.
   */
  const removeFrequentPassenger = (id) => {
    const passengerToDelete = frequentPassengers.find((passenger) => passenger.id === id);
    setFrequentPassengers(frequentPassengers.filter((passenger) => passenger.id !== id));
    trackEvent('Frequent Passenger Deleted', {
      'Passenger': {
        'First Name': passengerToDelete.firstName,
        'Last Name': passengerToDelete.lastName,
        'Email': passengerToDelete.email || 'N/A',
      },
      'Passenger Number': passengerToDelete.selectedForPassenger,
      'Selected': passengerToDelete.isSelected,
    });
    pendingPassengerDeletions.current.push({
      passenger: passengerToDelete,
      timeoutId: setTimeout(async () => {
        try {
          await userPreferencesService.deleteFrequentPassenger(id);
          removePendingPassengerDeletion(id);
          /**
           * Update store with the latest state.
           */
          dispatch(setFrequentPassengersAction(frequentPassengersRef.current));
        } catch (error) {
          undoDeleteFrequentPassenger(id);
          dispatch(setAlert('', 'error', t('errors:could_not_delete_frequent_passenger')));
        }
      }, 10000),
    });
  };

  /**
   * Selects a frequent passenger for a specific passenger index.
   * @param {string} id - ID of the frequent passenger to select.
   * @param {number} passengerIndex - Index of the passenger to apply the selection to.
   */
  const selectFrequentPassenger = useCallback(
    (id, passengerIndex, from = '') => {
      const newFrequentPassengers = frequentPassengers
        .map((passenger) => {
          if (passenger.selectedForPassenger === passengerIndex) {
            return { ...passenger, isSelected: false, selectedForPassenger: null };
          }
          return passenger;
        })
        .map((passenger) => {
          if (passenger.id === id) {
            return { ...passenger, isSelected: true, selectedForPassenger: passengerIndex };
          }
          return passenger;
        });

      setFrequentPassengers(newFrequentPassengers);
      dispatch(setFrequentPassengersAction(newFrequentPassengers));
      const selectedPassenger = newFrequentPassengers.find((passenger) => passenger.id === id);
      trackFrequentPassengerSelected(selectedPassenger, from);

      if (selectedPassenger) {
        dispatch(
          change('passengers', `passengers[${passengerIndex - 1}]`, {
            ...formPassengersState[passengerIndex - 1],
            firstName: selectedPassenger.firstName,
            lastName: selectedPassenger.lastName,
            email: selectedPassenger.email,
          }),
        );
      }
    },
    [dispatch, formPassengersState, frequentPassengers],
  );

  useEffect(() => {
    formPassengersState.forEach((formPassenger, index) => {
      const currentFrequentPassenger = frequentPassengers.find(
        (fp) => fp.selectedForPassenger === index + 1,
      );

      // Case 1: Reset if a selected frequent passenger's name is modified
      if (
        currentFrequentPassenger &&
        (formPassenger.firstName !== currentFrequentPassenger.firstName ||
          formPassenger.lastName !== currentFrequentPassenger.lastName)
      ) {
        setFrequentPassengers((prevState) => {
          return prevState.map((fp) => {
            return fp.selectedForPassenger === index + 1
              ? { ...fp, isSelected: false, selectedForPassenger: null }
              : fp;
          });
        });
      }

      // Case 2: Auto-match if name matches a frequent passenger
      if (
        !currentFrequentPassenger &&
        formPassenger.firstName?.trim() &&
        formPassenger.lastName?.trim()
      ) {
        const matchingPassenger = frequentPassengers.find(
          (fp) =>
            fp.firstName.toLowerCase() === formPassenger.firstName.toLowerCase() &&
            fp.lastName.toLowerCase() === formPassenger.lastName.toLowerCase() &&
            !fp.isSelected,
        );

        if (matchingPassenger) {
          const updatedPassenger = {
            ...formPassenger,
            email: matchingPassenger.email || '',
            firstName: matchingPassenger.firstName,
            lastName: matchingPassenger.lastName,
          };

          dispatch(change('passengers', `passengers[${index}]`, updatedPassenger));
          selectFrequentPassenger(matchingPassenger.id, index + 1, 'Auto Complete');
        }
      }
    });
  }, [formPassengersState, frequentPassengers, dispatch, selectFrequentPassenger]);

  const contextValue = {
    frequentPassengers,
    removeFrequentPassenger,
    selectFrequentPassenger,
    pendingPassengerDeletions,
    undoDeleteFrequentPassenger,
  };

  return (
    <FrequentPassengersContext.Provider value={contextValue}>
      {children}
    </FrequentPassengersContext.Provider>
  );
};

FrequentPassengersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FrequentPassengersProvider;
