import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon, InputRadio } from '@reservamos/elements';
import cn from 'utils/cn';

/**
 * RedeemPointsMethod component.
 * @param {object} props - label, value, checked, data, onChange, iconType.
 * @param {string} props.label - The label text to display.
 * @param {string} props.value - The value for the radio input.
 * @param {boolean} props.checked - Whether the radio input is checked.
 * @param {string} props.data - Additional text data to display.
 * @param {function} props.onChange - Function to call when the radio input changes.
 * @param {string} props.iconType - Type of icon to display, either "Email" or "Phone".
 * @returns {React.ReactNode}
 */
const RedeemPointsMethod = ({ label, value, checked, data, onChange, iconType = 'Phone' }) => {
  return (
    <div
      onClick={onChange}
      className={cn(
        'flex items-center w-full rounded-2xl py-[10px] px-4 gap-4 cursor-pointer',
        'shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)] hover:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.2)]',
      )}
    >
      <InputRadio isSmall value={value} isActive={checked} color="primaryStrong" />

      <div className="flex items-center">
        <Icon type={iconType} size="M" color="primaryStrong" />
      </div>

      <div className="flex flex-col items-start flex-grow">
        <Text color="primaryStrong" size="L" style={{ textAlign: 'left' }}>
          {label}
        </Text>
        <Text color="grayLight" size="L" style={{ textAlign: 'left' }}>
          {data}
        </Text>
      </div>
    </div>
  );
};

RedeemPointsMethod.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  data: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  iconType: PropTypes.oneOf(['Email', 'Mobile']),
};

export default RedeemPointsMethod;
