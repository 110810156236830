import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setError } from '@/actions';
import { generateNITCode, validateNITCode } from '../../../../actions/payment';

/**
 * Hook to handle the redeem confirmation
 * @returns {object} redeemConfirmation - The redeem confirmation
 */
const useRedeemConfirmation = () => {
  const dispatch = useDispatch();
  const [confirmationState, setConfirmationState] = useState({
    isCreating: false,
    isConfirming: false,
    codeSent: false,
    error: null,
  });

  const createNITCode = useCallback(
    async (notificationType) => {
      setConfirmationState((prev) => ({
        ...prev,
        isCreating: true,
      }));

      try {
        const response = await dispatch(generateNITCode({ notificationType }));

        if (response?.code === 'UNAUTHORIZED') {
          setConfirmationState((prev) => ({
            ...prev,
            error: 'generateNITCode',
          }));
          return;
        }

        setConfirmationState((prev) => ({
          ...prev,
          codeSent: !!response,
        }));
      } catch (error) {
        setConfirmationState((prev) => ({
          ...prev,
          error: 'generateNITCode',
        }));
      } finally {
        setConfirmationState((prev) => ({
          ...prev,
          isCreating: false,
        }));
      }
    },
    [dispatch],
  );

  const confirmNITCode = useCallback(
    async (code) => {
      setConfirmationState((prev) => ({
        ...prev,
        isConfirming: true,
        error: null,
      }));

      try {
        const response = await dispatch(validateNITCode(code));

        if (response?.code === 'UNAUTHORIZED') {
          setConfirmationState((prev) => ({
            ...prev,
            error: 'validateNITCode',
          }));
          return;
        }

        dispatch(setError(null, 'redeem_success', 'success', false));
        setConfirmationState((prev) => ({
          ...prev,
          isConfirming: false,
        }));

        return response;
      } catch (error) {
        setConfirmationState((prev) => ({
          ...prev,
          error: 'validateNITCode',
        }));
      } finally {
        setConfirmationState((prev) => ({
          ...prev,
          isConfirming: false,
        }));
      }
    },
    [dispatch],
  );

  const reset = useCallback(() => {
    setConfirmationState({
      isCreating: false,
      isConfirming: false,
      codeSent: false,
      error: null,
    });
  }, []);

  return {
    ...confirmationState,
    createNITCode,
    confirmNITCode,
    reset,
  };
};

export default useRedeemConfirmation;
