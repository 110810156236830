// import { Input } from '@reservamos/elements';
import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import usePasteByName from 'hooks/usePasteByName';
import cn from 'utils/cn';

/**
 * CodeInput component
 * @param {array} code - The code to display
 * @param {boolean} submitted - Whether the code has been submitted
 * @param {boolean} isLoading - Whether the code is loading
 * @param {function} onChange - The function to call when the code changes
 * @param {function} onPaste - The function to call when the code is pasted
 * @param {boolean} submitted - Whether the code has been submitted
 * @returns {React.ReactNode} The CodeInput component
 */
const CodeInput = ({ code = [], isLoading, onChange, onPaste, submitted }) => {
  const length = useRef(code.length);

  const handleOnFocus = (e) => {
    const { value } = e.target;
    if (value) e.target.select();
  };

  const handleOnChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (/[^0-9\b]/.test(value)) return;

      onChange({ index: name, value });

      const position = Number(name) + 1;
      const inputToFocus = document.getElementsByName(position)[0];
      if (/[0-9]/.test(value) && inputToFocus) inputToFocus.focus();
    },
    [onChange],
  );

  const handleOnKeyDown = useCallback((e) => {
    const { keyCode, target } = e;
    const { name, value } = target;

    if (value.length > 0 || keyCode !== 8) return;

    const inputToFocus = document.getElementsByName(Number(name) - 1)[0];
    if (inputToFocus) inputToFocus.focus();
  }, []);

  const handlePaste = useCallback(
    (pastedText) => {
      const sanitized = pastedText.replace(/\D/g, ''); // Keep only numeric characters
      const { length: sanitizedLength } = sanitized;

      const newCode = sanitized.slice(0, length.current);

      onPaste(newCode);

      const position =
        sanitizedLength - 1 > length.current ? length.current - 1 : sanitizedLength - 1;

      const inputToFocus = document.getElementsByName(position)[0];
      if (/[0-9]/.test(sanitized) && inputToFocus) inputToFocus.focus();
    },
    [onPaste],
  );

  usePasteByName('0', handlePaste);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${length.current}, 1fr)`,
        gap: '10px',
      }}
      className="max-w-[320px] mx-auto text-center"
    >
      {Array.from({ length: length.current }, (_, index) => (
        <input
          key={index}
          className={cn(
            'w-[46px] h-[58px] text-primary-strong font-bold text-2xl text-center rounded-md border border-gray-300 focus:border-primary-strong focus:outline-none',
            {
              'border-primary-strong': code[index],
              'border-error-strong': submitted && !code[index],
            },
          )}
          name={index}
          maxLength="1"
          value={code[index]}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          disabled={isLoading}
        />
      ))}
    </div>
  );
};

CodeInput.propTypes = {
  code: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onPaste: PropTypes.func.isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default CodeInput;
