import React from 'react';

import { LoaderDots, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import ModalWithTheme from 'components/ModalWithTheme';
import RedeemPointsMethods from './RedeemPointsMethods';
import RedeemCodeForm from './RedeemCodeForm/RedeemCodeForm';
import { useRedeemContext } from '../RedeemHandlerContext';

const RedeemPointsConfirmationModal = () => {
  const { isOpen, onClose, isCreating, isConfirming, codeSent } = useRedeemContext();

  // const [selectedMethod, setSelectedMethod] = useState(
  //   optContactMethod === 'both' ? 'email' : optContactMethod,
  // );

  const isProcessing = isConfirming || isCreating;

  const { t } = useTranslation('payment');

  // const handleOnSendConfirmation = useCallback(
  //   (method) => {
  //     if (codeSent) return;
  //     setSelectedMethod(method);
  //     createNITCode(method);
  //   },
  //   [createNITCode, codeSent],
  // );

  // const handleOnResendCode = useCallback(() => {
  //   createNITCode(selectedMethod);
  // }, [createNITCode, selectedMethod]);

  // const handleOnConfirm = useCallback(
  //   async (code) => {
  //     const response = await confirmNITCode(code);

  //     if (response) {
  //       onSuccess();
  //     }
  //   },
  //   [confirmNITCode, onSuccess],
  // );

  if (!isOpen) return null;

  return (
    <ModalWithTheme
      responsiveSize="S"
      onCloseModal={onClose}
      closeOnOverlayClick={!isProcessing}
      borderRadiusBigger
    >
      {isProcessing ? (
        <div className="flex flex-col gap-[10px] justify-center items-center h-full min-h-[300px]">
          <Text size="XXL" weight="bold">
            {t('label.verifying_code')}
          </Text>
          <LoaderDots isAccent size="M" />
        </div>
      ) : (
        <div className="flex flex-col gap-[10px]">
          {!codeSent ? (
            <>
              <Text size="XXL" weight="bold">
                {t('label.confirm_use_wallet')}
              </Text>
              <RedeemPointsMethods />
            </>
          ) : (
            <>
              <Text size="XXL" weight="bold">
                {t('label.enter_code')}
              </Text>
              <RedeemCodeForm />
            </>
          )}
        </div>
      )}
    </ModalWithTheme>
  );
};

export default RedeemPointsConfirmationModal;
