import { Text, Button, MessageBox } from '@reservamos/elements';
import React, { useCallback, useMemo, useState } from 'react';
import useCostapassState from 'hooks/useCostapassState';
import { useTranslation } from 'react-i18next';
import formatEmailPrivacy from 'utils/formatEmailPrivacy';
import formatPhonePrivacy from 'utils/formatPhonePrivacy';

import CodeInput from './CodeInputs';
import { useRedeemContext } from '../../RedeemHandlerContext';
/**
 * RedeemCodeForm is a component that allows the user to redeem points by entering a code.
//  * @param {boolean} isConfirming - Whether the form is confirming.
//  * @param {boolean} error - Whether the form has an error.
//  * @param {boolean} submitted - Whether the form has been submitted.
//  * @param {function} onConfirm - The function to call when the form is confirmed.
//  * @param {function} onResendCode - The function to call when the code is resent.
 * @returns {JSX.Element} - The RedeemCodeForm component.
 */
const RedeemCodeForm = () => {
  const { onConfirm, onResendCode, selectedMethod, error, isConfirming } = useRedeemContext();
  const {
    profile: { email, phone },
  } = useCostapassState();
  const [submitted, setSubmitted] = useState(false);
  const [code, setCode] = useState(['', '', '', '']);

  const { t } = useTranslation(['payment', 'general', 'notifications']);

  const isCodeValid = useMemo(() => {
    return code.every((value) => value !== '');
  }, [code]);

  const handleOnConfirm = useCallback(() => {
    setSubmitted(true);
    if (!isCodeValid) return;

    onConfirm(code.join(''));
  }, [onConfirm, isCodeValid, code]);

  const handleOnChange = useCallback(
    ({ index, value }) => {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
    },
    [code],
  );

  const handleOnPaste = useCallback(
    (newCode) => {
      const codeLength = code.length;
      const newCodeArray = newCode.split('');
      const matchedCode = Array.from({ length: codeLength }, (_, index) => newCodeArray[index]);
      setCode(matchedCode);
    },
    [code],
  );

  const methodValue = useMemo(() => {
    return selectedMethod === 'email' ? formatEmailPrivacy(email) : formatPhonePrivacy(phone);
  }, [selectedMethod, email, phone]);

  return (
    <div className="flex flex-col pt-2 gap-6">
      <Text color="grayStrong" size="L">
        {t(`wallet_instructions.sent_code_${selectedMethod}`)}
        <strong className="text-primary-regular">{methodValue}. </strong>
        {t(`wallet_instructions.sent_code_${selectedMethod}_2`)}
      </Text>

      <CodeInput
        code={code}
        isLoading={isConfirming}
        submitted={submitted}
        onChange={handleOnChange}
        onPaste={handleOnPaste}
      />
      {error === 'validateNITCode' && (
        <MessageBox borderColor="error">
          <Text size="L" color="error">
            {t('notifications:error.validating_code')}
          </Text>
        </MessageBox>
      )}

      <div className="flex justify-center">
        <p className="text-gray-500 text-base">No has recibido el código?</p>
        <button
          type="button"
          className="text-primary-regular text-base underline border-none bg-transparent ml-1 hover:text-primary-dark"
          onClick={onResendCode}
        >
          {t('general:button.resend_code')}
        </button>
      </div>
      <Button
        fullWidth
        text={t('general:button.confirm')}
        variant="primary"
        fontSize="M"
        borderRadius="M"
        onClick={handleOnConfirm}
      />
    </div>
  );
};

RedeemCodeForm.propTypes = {};

export default RedeemCodeForm;
