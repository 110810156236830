import * as types from 'constants/ActionTypes';
import { Map, fromJS } from 'immutable';

const defaultPayment = Map({
  lastUpdated: null,
  loaded: false,
  cardErrorOccured: false,
  loadingOTP: false,
  showOTPModal: false,
  otpConfirmed: false,
  otpTriggerPayment: false,
  pointsUsed: 0,
  otpError: '',
  otpErrorCode: false,
  // ReedemFlow
  showRedeemModal: false,
  nitCodeGenerated: false,
  nitCodeValidated: false,
});

export default function payment(payment = defaultPayment, action) {
  switch (action.type) {
    case types.RESET_PAYMENT_CARD_ERROR:
      return payment.set('cardErrorOccured', false);

    case types.RESET_PAYMENT:
      return defaultPayment;

    case types.REQUEST_PAYMENT:
      return payment.set('isFetching', true);

    case types.RECEIVE_PAYMENT:
      return payment.merge(
        fromJS({
          isFetching: false,
          loaded: true,
          ...action.payload,
        }),
      );

    case types.SET_CARD_ERROR_OCCURED:
      return payment.set('cardErrorOccured', true);

    case types.LOADING_OTP:
      return payment
        .set('loadingOTP', action.payload.loading)
        .set('showOTPModal', action.payload.showOTPModal)
        .set('otpError', false)
        .set('otpErrorCode', false);

    case types.OTP_CREATION:
      return payment
        .set('loadingOTP', false)
        .set('showOTPModal', action.payload.showOTPModal)
        .set('otpConfirmed', false);

    case types.OTP_CONFIRMATION:
      return payment
        .set('loadingOTP', false)
        .set('showOTPModal', action.payload.showOTPModal)
        .set('otpConfirmed', action.payload.otpConfirmed)
        .set('otpTriggerPayment', action.payload.otpConfirmed);

    case types.OTP_ERROR:
      return payment
        .set('otpError', action.payload.otpError)
        .set('otpErrorCode', action.payload.otpErrorCode);

    case types.OTP_CLEAR:
      return payment.set('otpError', false).set('otpErrorCode', false).set('showOTPModal', false);

    case types.REDEEM_MODAL_TOGGLE:
      return payment.set('showRedeemModal', action.payload);

    case types.SET_NIT_CODE_GENERATED:
      return payment.set('nitCodeGenerated', true);

    case types.SET_NIT_CODE_VALIDATED:
      return payment.set('nitCodeValidated', true);

    default:
      return payment;
  }
}
