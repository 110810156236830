import { useEffect } from 'react';

/**
 * Hook to handle paste event on an element
 * @param {string} inputName - Name of the input to handle the paste event
 * @param {Function} callback - Callback function to handle the paste event
 */
function usePasteByName(inputName, callback) {
  useEffect(() => {
    const handlePaste = (event) => {
      event.preventDefault();
      const pastedText = event.clipboardData?.getData('text') || '';
      callback(pastedText);
    };

    const input = document.querySelector(`input[name="${inputName}"]`);
    if (input) {
      input.addEventListener('paste', handlePaste);
    } else {
      console.warn(`Input with name "${inputName}" not found.`);
    }

    return () => {
      if (input) {
        input.removeEventListener('paste', handlePaste);
      }
    };
  }, [inputName, callback]);
}

export default usePasteByName;
