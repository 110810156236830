import React from 'react';
import { Text, Button, MessageBox } from '@reservamos/elements';
import useCostapassState from 'hooks/useCostapassState';
import { useTranslation } from 'react-i18next';
import formatPhonePrivacy from 'utils/formatPhonePrivacy';
import formatEmailPrivacy from 'utils/formatEmailPrivacy';
import ConfirmationMethod from './RedeemPointsMethod';
import { useRedeemContext } from '../../RedeemHandlerContext';

/**
 * @description Confirmation methods component
 * @returns {React.ReactNode}
 */
const RedeemPointsMethods = () => {
  const { onSendConfirmation, optContactMethod, selectedMethod, onSelectMethod, error } =
    useRedeemContext();

  const {
    profile: { email, phone },
  } = useCostapassState();
  const { t } = useTranslation(['payment', 'notifications']);

  return (
    <>
      <div className="mb-4">
        <Text color="grayMedium" size="L">
          {t('wallet_instructions.select_method')}
        </Text>
      </div>
      <div className="flex flex-col gap-6">
        {['email', 'both'].includes(optContactMethod) && (
          <ConfirmationMethod
            label={t('wallet_methods.email')}
            value="email"
            checked={selectedMethod === 'email'}
            onChange={() => onSelectMethod('email')}
            data={formatEmailPrivacy(email)}
            iconType="Email"
          />
        )}
        {['sms', 'both'].includes(optContactMethod) && (
          <ConfirmationMethod
            label={t('wallet_methods.sms')}
            value="sms"
            checked={selectedMethod === 'sms'}
            onChange={() => onSelectMethod('sms')}
            data={formatPhonePrivacy(phone)}
            iconType="Mobile"
          />
        )}
        {error === 'generateNITCode' && (
          <MessageBox borderColor="error">
            <Text color="error" size="L">
              {t('notifications:error.generating_code')}
            </Text>
          </MessageBox>
        )}

        <Button
          fullWidth
          fontSize="M"
          borderRadius="M"
          text={t('wallet_methods.send_code')}
          variant="primary"
          onClick={() => onSendConfirmation()}
          isLoading={false}
        />
      </div>
    </>
  );
};

export default RedeemPointsMethods;
