import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@reservamos/elements';
import { formatPassengerName } from 'utils/userPreferences';
import useFrequentPassengersContext from '../context/FrequentPassengers';

/**
 * FrequentPassengerNav component.
 * Renders a horizontal scrollable list of frequent passenger buttons for quick selection.
 * @param {object} props - passengerIndex
 * @param {number} props.passengerIndex - The index of the current passenger being selected
 */
const FrequentPassengerNav = ({ passengerIndex }) => {
  const { frequentPassengers, selectFrequentPassenger } = useFrequentPassengersContext();

  return (
    <div className="flex w-full overflow-x-scroll whitespace-nowrap space-x-2 border-r border-r-gray-200 py-1">
      {frequentPassengers.map((passenger) => (
        <Button
          isDisabled={passengerIndex !== passenger.selectedForPassenger && passenger.isSelected}
          key={passenger.id}
          iconType="emojiAdult"
          padding="S"
          mobileSize="XS"
          text={formatPassengerName({
            firstName: passenger.firstName,
            lastName: passenger.lastName,
            initialOnly: true,
          })}
          colorActiveSelection={
            passengerIndex === passenger.selectedForPassenger && passenger.isSelected
              ? 'successStrong'
              : undefined
          }
          alphaBg={
            passengerIndex === passenger.selectedForPassenger && passenger.isSelected
              ? undefined
              : '35'
          }
          variant={
            passengerIndex !== passenger.selectedForPassenger && passenger.isSelected
              ? 'gray'
              : undefined
          }
          onClick={() =>
            passenger.isSelected && passenger.selectedForPassenger !== passengerIndex
              ? null
              : selectFrequentPassenger(passenger.id, passengerIndex, 'List')
          }
          isRounded
          hideShadow={passengerIndex !== passenger.selectedForPassenger && passenger.isSelected}
        />
      ))}
    </div>
  );
};

FrequentPassengerNav.propTypes = {
  passengerIndex: PropTypes.number.isRequired,
};

export default FrequentPassengerNav;
