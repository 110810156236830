import { createContext, useContext } from 'react';

/**
 * Context for Redeem functionality
 */
export const RedeemContext = createContext(null);

/**
 * Custom hook to use the RedeemContext
 */
export const useRedeemContext = () => {
  const context = useContext(RedeemContext);
  if (!context) {
    throw new Error('useRedeemContext must be used within a RedeemContextProvider');
  }
  return context;
};
