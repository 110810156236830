import React, { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useCostapassState from 'hooks/useCostapassState';
import { getSessionId } from 'utils/session';
import { CurrentLang } from 'utils/lang';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';

const cleanBrand = (brand) => {
  // Remove all - and replace with _
  return brand.replace(/-/g, '').toLowerCase();
};

/**
 * TpFundWidget component. Loads the TP Fund Widget from the specified URL and configures it with required variables.
 * @param {Object} props - Component props
 * @returns {JSX} - React component
 */
const TpFundWidget = () => {
  const widgetSelector = useRef(uuidv4());
  const costapassState = useCostapassState();
  const sessionId = getSessionId();
  const {
    tpFundWidget: { version, isSandbox },
  } = useWhitelabelEnvs();

  const { brand, token } = costapassState?.profile || {};
  const profileToken = token;
  const lang = CurrentLang();

  const onDepositSuccess = () => {
    if (window.TPFundWidget && window.TPFundWidget.isInitialized) {
      window.TPFundWidget.onDepositSuccess(() => {
        // reload the page
        window.location.reload();
      });
    }
  };

  // Creates the TP Fund Widget with the current selector
  const createTpFundWidget = () => {
    if (window.TPFundWidget && window.TPFundWidget.isInitialized) {

    } else {

    }
  };

  useEffect(() => {
    // Create script element for the widget
    const script = document.createElement('script');
    script.src = `https://widgets.reservamos.mx.s3.amazonaws.com/tp-fund/${version}/widget.js`;
    // script.src = 'http://localhost:3334/widget.js';
    script.setAttribute('data-target-id', widgetSelector.current);

    // Handle script loading events
    script.onload = () => {

      createTpFundWidget();
      onDepositSuccess();
    };

    script.onerror = () => {

    };

    // Add script to document
    document.body.appendChild(script);

    // Clean up on component unmount
    return () => {
      if (window.TPFundWidget && window.TPFundWidget.isInitialized) {

        window.TPFundWidget.destroy();
      }
      document.body.removeChild(script);
    };
  }, [version]);

  return (
    <div
      id={widgetSelector.current}
      data-token={profileToken}
      data-session-id={sessionId}
      data-brand={cleanBrand(brand || '')}
      data-locale={lang}
      data-is-sandbox={isSandbox}
    />
  );
};

export default TpFundWidget;
