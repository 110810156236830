import { useSelector } from 'react-redux';

/**
 * Hook to get the costapass state
 * @returns {object} costapassState - The costapass state
 */
const useCostapassState = () => {
  const costapassState = useSelector((state) => state.costapass.toJS());
  return costapassState;
};

export default useCostapassState;
