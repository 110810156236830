import React from 'react';
import PropTypes from 'prop-types';
import { Currency, Spacing, Text } from '@reservamos/elements';

const LoyaltyPricingRow = ({
  description,
  currency,
  bold,
  canApplyPoints,
  doterPointsLabel,
  highlightColor,
  size,
  mobileSize,
}) => {
  const textColor = highlightColor ? 'loyaltyLight' : 'grayStrong';
  return (
    <Spacing justifyContent="space-between" alignItems="center" fullWidth>
      <Text weight={bold ? 'bold' : 'regular'} size={size} mobileSize={mobileSize}>
        {description}
      </Text>

      {canApplyPoints ? (
        <Currency
          price={currency}
          currency="MXN"
          size={size}
          mobileSize={mobileSize}
          weight={bold ? 'bold' : 'regular'}
        />
      ) : (
        <Text
          weight={bold || highlightColor ? 'bold' : 'regular'}
          size={size}
          mobileSize={mobileSize}
          color={textColor}
        >
          {currency} {doterPointsLabel}
        </Text>
      )}
    </Spacing>
  );
};

LoyaltyPricingRow.propTypes = {
  description: PropTypes.string.isRequired,
  currency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bold: PropTypes.bool,
  canApplyPoints: PropTypes.bool,
  highlightColor: PropTypes.bool,
  doterPointsLabel: PropTypes.string,
  size: PropTypes.string,
  mobileSize: PropTypes.string,
};

LoyaltyPricingRow.defaultProps = {
  bold: false,
  canApplyPoints: false,
  doterPointsLabel: '',
  highlightColor: '',
  size: 'M',
  mobileSize: 'S',
};

export default LoyaltyPricingRow;
