import TpFundWidget from './TpFundWidget';

export default TpFundWidget;

export const openTpFundWidget = () => {
  if (window.TPFundWidget && window.TPFundWidget.isInitialized && window.TPFundWidget.openModal) {
    window.TPFundWidget.openModal();
  } else {
    console.warn('TP Fund Widget is not initialized yet or openModal method is not available');
  }
};
