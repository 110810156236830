/**
 * Formats a phone number to display only the first 2 and last 4 digits
 * with asterisks replacing the middle digits.
 * Example: 8123504207 becomes +81 **** **** 4207
 *
 * @param {string|number} phoneNumber - The phone number to format (10 digits expected)
 * @returns {string} The formatted phone number
 */
export default function formatPhonePrivacy(phoneNumber) {
  // Convert to string if it's a number
  const phone = String(phoneNumber);

  // Validate input - must have at least 6 digits (for 2 at start + 4 at end)
  if (!phone || phone.length < 6) {
    return phone;
  }

  // Extract the first 2 digits and last 4 digits
  const firstTwoDigits = phone.substring(0, 2);
  const lastFourDigits = phone.substring(phone.length - 4);

  // Format the phone number with asterisks in the middle
  return `+${firstTwoDigits} **** **** ${lastFourDigits}`;
}
