/**
 * Formats an email address to display only the first few characters of the local part
 * with asterisks replacing the remaining characters, while keeping the domain intact.
 *
 * Examples:
 * - brandon.reservamos@gmail.com becomes bran*********@gmail.com
 * - test@gmail.com becomes te*********@gmail.com
 *
 * @param {string} email - The email address to format
 * @returns {string} The masked email address
 */
export default function formatEmailPrivacy(email) {
  // Return as is if empty or not a valid email (basic check for @)
  if (!email || !email.includes('@')) {
    return email;
  }

  // Split the email into local part and domain
  const [localPart, ...domainParts] = email.split('@');
  const domain = `@${domainParts.join('@')}`; // Handle emails with multiple @ symbols

  // Determine how many characters to show based on local part length
  const charsToShow = localPart.length > 4 ? 4 : 2;

  // Get the visible part of the local part
  const visiblePart = localPart.substring(0, charsToShow);

  // Use a fixed number of asterisks (9) as in the examples
  const maskedAsterisks = '*********';

  // Combine the parts to form the masked email
  return `${visiblePart}${maskedAsterisks}${domain}`;
}
